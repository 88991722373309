<template>
  <g :fill="color" fill-rule="evenodd">
    <defs>
      <path
        id="icon_credit_card_a"
        d="M15.75 0C16.993 0 18 .97 18 2.167v8.666C18 12.03 16.993 13 15.75 13H2.25C1.007 13 0 12.03 0 10.833V2.167C0 .97 1.007 0 2.25 0h13.5zm.75 5.777h-15v5.056c0 .4.336.723.75.723h13.5c.414 0 .75-.324.75-.723V5.777zm-.75-4.333H2.25c-.414 0-.75.324-.75.723v2.166h15V2.167c0-.4-.336-.723-.75-.723z"
      />
    </defs>
    <mask id="icon_credit_card_b" fill="#fff">
      <use xlink:href="#icon_credit_card_a" />
    </mask>
    <use fill="#000" xlink:href="#icon_credit_card_a" />
    <g :fill="color" class="icon-color" mask="url(#icon_credit_card_b)">
      <path d="M0 0H19.436V19.728H0z" transform="translate(0 -3)" />
    </g>
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
